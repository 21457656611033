import React,{useEffect,useState} from 'react'
import "./Table.css"
import useReactIpLocation from "react-ip-details";
import getSymbolFromCurrency from 'currency-symbol-map'

function Row({ h1, c1, c2, c3, c4 }) {

    return (
        <tr>
            <td style={{ padding: "5px" }} >
                {h1}
            </td>
            <td>
                <table className='element' >
                    <td style={{ borderRight: "1px solid black" }} >
                        {c1}
                    </td>
                    <td>
                        {c2}
                    </td>
                </table>
            </td>
            <td>
                <table className='element' >
                    <td style={{ borderRight: "1px solid black", overflowWrap:"break-word" }} >
                        {c3}
                    </td>
                    <td >
                        {c4}
                    </td>
                </table>
            </td>
        </tr>

    )
}

function Table() {
    const {currency, exchangeRate,exchangeRateResponse} = useReactIpLocation({ numberToConvert: 100 });
    const [fetch, setfetch] = useState(false)

    useEffect(() => {
        try {
            if (exchangeRateResponse.rates) {
                setfetch(true)
            }else{
            }
            
        } catch (error) {
            
        }

    }, [exchangeRateResponse])
    

    function price(data){
        const amount =  getSymbolFromCurrency(currency)+ ((data/exchangeRateResponse.rates.INR)*exchangeRate).toFixed(2) 
        return amount
    }

    
    return (
        <>
            <div style={{ marginTop: "30px" }} id="tariffs" className='title' >
                Tarrif
            </div>
            {fetch ?
                        <div >
                        <table className='table'>
                            <tr >
                                <th ></th>
                                <th >OUT STATIONS</th>
                                <th >LOCAL</th>
                            </tr>
                            <Row h1={""} c1="DAY RENT" c2="KM CHARGE" c3="DAY RENT" c4="KM CHARGE" />
                            <Row h1={"SLEEPER COACH A/C ONLY"} c1={price(7500)} c2={price(25)} c3={price(8000)} c4={price(25)} />
                            <Row h1={"TEMPO TRAVELER 12/14 SEATS"} c1={`${price(3000)} / ${price(3500)} (A/C)`} c2={`${price(16)} / ${price(19)} A/c`} c3={price(3500)} c4={price(16)} />
                            <Row h1={"COACH"} c1={price(3500)} c2={price(20)} c3={`${price(3500)}(25Km Free)`} c4={price(20)} />
                            <Row h1={"INNOVA CRYSTA A/C"} c1={price(3000)} c2={price(16)} c3={`${price(4000)}(100Km Free)`} c4={price(16)} />
                            <Row h1={"INNOVA A/C"} c1={price(2400)} c2={price(14)} c3={`${price(3500)}(100 Km Free)`} c4={price(14)} />
                            <Row h1={"ETIOS A/C"} c1={price(1800)} c2={price(10)} c3={`${price(350)} (1 Hour)(10 Km Free)`} c4={price(13)} />
                            <Row h1={"SWIFT DEZIRE A/C"} c1={price(1500)} c2={price(10)} c3={`${price(300)} (1 Hour)(10 Km Free)`} c4={price(13)} />
                            <Row h1={"INDICA A/C"} c1={price(1300)} c2={price(10)} c3={`${price(250)} (1 Hour)(10 Km Free)`} c4={price(10)} />
        
                        </table>
                    </div>
                    : <div style={{width:"100%",textAlign:"center"}} >Loading...</div>
            }

            <div className='terms' >
                <div className='terms_heading' >
                    TERMS & CONDITIONS
                </div>
                <div>
                    • A/c not Available in Hill Station.
                    • Km Measured from Office to Office Only.<br />
                    • 2000 rupees for booking the tour days... 50% advance payment
                    is required from the approximate amount before the travel dates.<br />
                    • No driver Batta. • Toll + T.Permit + Parking Extra.
                </div>
            </div>
        </>

    )
}

export default Table