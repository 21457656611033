import React from 'react'
import "./AboutUs.css"
import images from '../../assets/images'
import RequestForm from '../../components/RequestForm/RequestForm'
import FeatureCard from '../../components/FeatureCard/FeatureCard'

function AboutUs() {
    return (
        <div id='about' className='about_us' >
            <div className='title' >
                about us
            </div>
            <article className='article_1' >
                The first luxury tempo traveler now in Coimbatore!!
                Enjoy a comfortable journey to a wonderful destination to have an amazing time.
            </article>
            <img className='car_image' alt='car' src={images.image20} />
            <div style={{marginTop:"80px",marginBottom:"30px"}} className='title gap' >
                Why Choose us
            </div>
            <div className='sub_section' >
                <div className='features' >
                    <div className='features_items' >
                        <div className='features_items_num' >
                            01
                        </div>
                        <div className='features_items_text' >
                            <div className='text_highight'>
                                Experienced Drivers
                            </div>
                            <div>
                                Our Drivers are well trained, Licensed, and verified
                            </div>
                        </div>
                    </div>
                    <div className='features_items' >
                        <div className='features_items_num' >
                            02
                        </div>
                        <div className='features_items_text' >
                            <div className='text_highight' >
                                Reliable services
                            </div>
                            <div>
                                Serving customers in and around Coimbatore in their journey for the last 5 years.
                            </div>
                        </div>
                    </div>
                    <div className='features_items' >
                        <div className='features_items_num' >
                            03
                        </div>
                        <div className='features_items_text' >
                            <div className='text_highight' >
                                Affordable Price
                            </div>
                            <div>
                                We provide 40% Cheaper than other sleeper tempo services
                            </div>
                        </div>
                    </div>
                    <div className='features_items' >
                        <div className='features_items_num' >
                            04
                        </div>
                        <div className='features_items_text' >
                            <div className='text_highight' >
                                Customer service
                            </div>
                            <div>
                                We are available on Call / WhatsApp 24/7 to assist you
                            </div>
                        </div>
                    </div>
                </div>
                <div id='request' className='request' >
                    <RequestForm />
                </div>
            </div>

            <div className='features_headline' >
                <div className='title gap ' >
                    Features
                </div>
                <div className='features_container' >
                    <FeatureCard icon={images.driver} heading="Drivers" text="We provide trained drivers and regional 
                driver services to ensure customers have a safe and comfortable travel experience." />

                    <FeatureCard icon={images.gst} heading="GST" text="GST bills will be provided with add-on charges
                 for better understanding." />

                    <FeatureCard icon={images.ac} heading="AC/only vehicles" text="We provide AC only vehicles for all
                  routes and provide AC on Hilly Area." />
                </div>

            </div>

        </div>
    )
}

export default AboutUs