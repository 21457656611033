import React from 'react'
import images from '../../assets/images'
import Slider from '../../components/Slider/Slider'
import "./Slides.css"

function Slides() {
    return (
        <div className='slides'>
            <Slider slides={[images.image19, images.image4]} />
            <div className='text_lines'>
                WELCOME TO LEO HOLIDAYS
                TOURS AND TRAVELS
            </div>
            <button  className='Slide_btn' >
                <a href='#request' >BOOK NOW</a>
            </button>
        </div>
    )
}

export default Slides