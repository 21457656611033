import React, { useRef, createRef } from 'react'
import "./RequestForm.css"
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaKey = "6Lccgx8lAAAAAAwPaYmlrDCYqwU2X1hW7DkqTGL0"
const number = 9087155559

function RequestForm() {
    const nameRef = useRef()
    const emailRef = useRef()
    const numRef = useRef()
    const recaptchaRef = createRef();

    const onSubmitWithReCAPTCHA = async (e) => {
        e.preventDefault();

        const message = `Name:${nameRef.current.value} Email:${emailRef.current.value} Number:${numRef.current.value}`

        if (window.innerWidth>450) {
            let url = `https://web.whatsapp.com/send?phone=${number}`;

            url += `&text=${encodeURI(message)}&app_absent=0`;
      
            window.open(url);
        }else{
            const messageText = encodeURIComponent(message);
            window.location.href = `https://wa.me/${number}?text=${messageText}`;
        }

          nameRef.current.value = ""
          emailRef.current.value = ""
          numRef.current.value = ""
            

    }

    return (
        <div style={{ width: "100%" }} >
            <form className="login_box" onSubmit={onSubmitWithReCAPTCHA}>
                <div className='headline_text' >Request A Callback</div>
                <input
                    className="inputBox"
                    type="name"
                    placeholder="Name"
                    required
                    ref={nameRef}
                />

                <input
                    className="inputBox"
                    type="email"
                    placeholder="Email"
                    ref={emailRef}
                    required
                />

                <input
                    type="number"
                    className="inputBox"
                    placeholder="Contact Number"
                    ref={numRef}
                    required
                />

                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={recaptchaKey}
                />

                <button className="btn" type="submit" >
                    Submit
                </button>
            </form>
        </div>
    )
}

export default RequestForm