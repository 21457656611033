import React from 'react'
import images from "../../assets/images"
import "./Footer.css"
import { useNavigate } from "react-router-dom";


function Footer() {
    const navigate = useNavigate()

    return (
        <footer >
            <div className='image' >
                <img src={images.logo2} alt='footer' className="footer_logo" />
            </div>
            <div className='tags' >
                <p onClick={() => navigate("/")} ><a href='#about' >About us</a></p>
                <p onClick={() => navigate("/")} ><a href='#request' >Book now</a></p>
                <p onClick={() => navigate("/gallery")} ><a href='#vacation' >Official/Vacation</a></p>
                <p onClick={() => navigate("/gallery")} ><a>Gallery</a></p>
                <p onClick={() => navigate("/")} ><a href='#tariffs' >Tariff</a></p>
            </div>
            <div className='contact' >
                <p style={{ fontWeight: "700" }} >Contact</p>
                <p>Leo Holidays Tours and Travels
                    Ramanathapuram,
                    Coimbatore -641045
                </p>
                <p>Phone number : +91 90871 55559 <br /> +91 90872 55559
                </p>
                <p>E-mail : leoholidays.coimbatore@gmail.com
                </p>
            </div>
        </footer>
    )
}

export default Footer