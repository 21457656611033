import React from 'react'
import Seating from '../../containers/Seating/Seating'
import Trip from '../../containers/Trip/Trip'
import "./Gallery.css"

function Gallery() {
    return (
        <>
            <Trip />
            <Seating />

            <div className='gallery' >
                <div class="grid-container">
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175540.jpg?alt=media&token=cfd26464-0b3c-470b-a483-3e79105b7f41" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175649.jpg?alt=media&token=39403517-669f-4d10-93b0-ee6dc5dd3287" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175822.webp?alt=media&token=4a482353-cee4-46f1-a900-8a4858feed5b" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175843.webp?alt=media&token=23457805-6a99-443e-aa29-9171913184b0" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175845.webp?alt=media&token=59aba15a-8119-49b2-b26b-e98357483cef" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175859.webp?alt=media&token=24d63a2c-195e-4b11-897b-b817120221f2" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175911.webp?alt=media&token=7a518771-ed02-4083-9516-ee9aa3c1e090" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175916.webp?alt=media&token=4c1145c0-14ef-42f0-a3e2-60750c140b25" alt="_img" />
                    <img src="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230225_132455.jpg?alt=media&token=fec0ba42-ed2f-4720-92b1-4ffe3135f6ef" alt="_img" />

                </div>
            </div>
        </>

    )
}

export default Gallery