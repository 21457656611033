import React from 'react'
import Table from '../../components/Table/Table'
import AboutUs from '../../containers/AboutUs/AboutUs'
import Slides from '../../containers/Slides/Slides'


function Home() {
    return (
        <div>
            <Slides />
            <AboutUs />
            <Table />
        </div>
    )
}

export default Home