import { Routes, Route } from "react-router-dom";
import './App.css';
import Header from './components/Header/Header';
import Navbar from './components/Navbar/Navbar';
import Footer from './containers/Footer/Footer';
import Gallery from "./pages/Gallery/Gallery";
import Home from './pages/Home/Home';


function App() {
  return (
    <div className="App" >
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
