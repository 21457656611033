import React from 'react'
import images from "../../assets/images"
import Card from '../../components/Card/Card'
import "./Seating.css"

const size = { height: "242px", width: "369px" }

function Seating() {
    return (
        <div className='seating' >
            <p className='title' >
                SEATING OPTIONS
            </p>
            <div>
                <p className='headline' >
                    Seating Option 1:
                </p  >
                <div className='cards' >
                    <Card size={size} image={images.image101} text={"Sleeper = 2 Adults + 2 kid"} />
                    <Card size={size} image="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175916.webp?alt=media&token=4c1145c0-14ef-42f0-a3e2-60750c140b25" text={"Semi sleeper = 3 adults"} />
                    <Card size={size} image="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230225_132455.jpg?alt=media&token=fec0ba42-ed2f-4720-92b1-4ffe3135f6ef" text={"Seating = 5 Adults"} />
                </div>
            </div>
            <div style={{ marginTop: "50px" }} >
                <p className='headline' >
                    Seating Option 2
                </p  >
                <div className='cards' >
                    <Card size={size} image="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175911.webp?alt=media&token=7a518771-ed02-4083-9516-ee9aa3c1e090" text={"Big-boss Seat = 2+2(4 adults)"} />
                    <Card size={size} image="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230220_175916.webp?alt=media&token=4c1145c0-14ef-42f0-a3e2-60750c140b25" text={"Semi sleeper = 3 adults"} />
                    <Card size={size} image="https://firebasestorage.googleapis.com/v0/b/leotourstravels-28507.appspot.com/o/IMG_20230225_132455.jpg?alt=media&token=fec0ba42-ed2f-4720-92b1-4ffe3135f6ef" text={"Seating = 5 Adults"} />
                </div>
            </div>
        </div>
    )
}

export default Seating