import React from 'react'
import images from '../../assets/images'
import Card from '../../components/Card/Card'
import "./Trip.css"

const size = { height: "275px", width: "398px" }


function Trip() {
    return (
        <div id='vacation' className='trip' >
            <p className='title trip_p ' >
                For official trip
            </p>
            <div className='trip_cards' >
                <Card size={size} image={images.image9} text={"32-inch TV for projecting presentations"} />
                <Card size={size} image={images.image10} text={" 2 in-built Wi-Fi’s for seamless connectivity"} />
                <Card size={size} image={images.image11} text={"Mic and Speakers for online meeting during trave"} />
                <Card size={size} image={images.laptop} text={"2 Laptop charging ports are available"} />
            </div>

            <p className='title trip_p' > 
                FOR vacation trip
            </p>
            <div className='trip_cards' >
                <Card size={size} image={images.image14} text={" OTT Platforms with subscriptions available for online streaming"} />
                <Card size={size} image={images.image15} text={" High Quality Mic with JBL woofer and 6 Pioneer Audio Systems"} />
                <Card size={size} image={images.image16} text={" Disco lights for entertainment purpose"} />
                <Card size={size} image={images.image9} text={"32-inch smart TV "} />
            </div>

        </div>
    )
}

export default Trip