import React from 'react'
import "./FeatureCard.css"

function FeatureCard({ icon, heading, text }) {
    return (
        <div className='feature_card' >
            <img src={icon} alt="card" className='feature_img' />
            <div className='feature_content' >
                <div className='feature_heading' >
                    {heading}
                </div>
                <div className='feature_text' >
                    {text}
                </div>
            </div>

        </div>
    )
}

export default FeatureCard