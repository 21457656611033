import email from "./email 1.png"
import facebook from "./facebook 1.png"
import image8 from "./image 8.png"
import instagram from "./instagram 1.png"
import telephone from "./telephone 1.png"
import whatsapp from "./whatsapp 1.png"
import logo from "./LEO Travels 1.png"
import image6 from "./image 6.png"
import seat1 from "./seat1.png"
import seat2 from "./seat2.png"
import image16 from "./image16.png"
import image10 from "./image10.png"
import image11 from "./image11.png"
import image9 from "./image9.png"
import image15 from "./image15.png"
import image14 from "./image14.png"
import logo2 from "./LEO Travels 3.png"
import image1 from "./image1.png"
import image4 from "./image4.png"
import image17 from "./image 17.png"
import image19 from "./image 19.png"
import image20 from "./aboutus.jpeg"
import ac from "./567301.png"
import gst from "./bill-icon.png"
import driver from "./driver-icon-33.jpg"
import laptop from "./34525.png"
import image101 from "./image101.jpeg"




export default {
    email, facebook, image8, instagram, logo, telephone, whatsapp, image6, seat2, seat1, image16, image11, 
    image14, image10, image9, image15, logo2, image1, image4, image17, image19, image20, ac, gst, 
    driver, laptop, image101
}

