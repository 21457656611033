import React, { useState } from 'react'
import "./Navbar.css"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import images from '../../assets/images'
import { useNavigate } from "react-router-dom";


function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const navigate = useNavigate()

    return (
        <div style={{ display: "grid" }} >
            <header className='navbar'>
                <div className='navbar__title navbar__item'>
                    <img src={images.logo} alt='logo' className="logo" />
                </div>
                <div className='container' >
                    <div onClick={() => navigate("/")} className='container__item'>Home</div>
                    <div onClick={() => navigate("/")} className='container__item'>  <a href="#about" >About Us</a></div>
                    <div onClick={() => navigate("/gallery")} className='container__item'> <a href='#vacation' >Official/Vacation</a> </div>
                    <div onClick={() => navigate("/")} className='container__item'> <a href='#tariffs' >Tariffs</a></div>
                    <div onClick={() => navigate("/gallery")} className='container__item'>Gallery</div>
                </div>
 

                <div className="navbar-menu">
                    {toggleMenu
                        ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                        : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}

                </div>

            </header>
            <div style={{ zIndex: 30 }} >
                {toggleMenu && (
                    <div className="navbar-menu_container">
                        <div className='menu_container' >
                            <div onClick={() => navigate("/")} className='container__item'>Home</div>
                            <div onClick={() => navigate("/")} className='container__item'>  <a href="#about" >About Us</a></div>
                            <div onClick={() => navigate("/gallery")} className='container__item'> <a href='#vacation' >Official/Vacation</a> </div>
                            <div onClick={() => navigate("/")} className='container__item'> <a href='#tariffs' >Tariffs</a> </div>
                            <div onClick={() => navigate("/gallery")} className='container__item'>Gallery</div>
                        </div>
                    </div>
                )}
            </div>

            {window.innerWidth < 450
                ?
                <img src={images.image17} width="100%" alt='strip' className='strip' />
                :
                <img src={images.image8} width="100%" alt='strip' className='strip' />
            }


        </div>
    )
}

export default Navbar