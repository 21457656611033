import React from 'react'
import "./Header.css"
import images from "../../assets/images"

const emailAddress = "leoholidays.coimbatore@gmail.com"
const subject = "Inquiry"
const body = "yo wassap mann"
const number = 9087155559
const message = "For Inquiry"

function Header() {

  function email() {
    const subjectLine = encodeURIComponent(subject);
    const emailBody = encodeURIComponent(body);
    window.location.href = `mailto:${emailAddress}?subject=${subjectLine}&body=${emailBody}`;
  }

  function whatsapp() {
    if (window.innerWidth > 450) {
      let url = `https://web.whatsapp.com/send?phone=${number}`;

      url += `&text=${encodeURI(message)}&app_absent=0`;

      window.open(url);
    } else {
      const messageText = encodeURIComponent(message);
      window.location.href = `https://wa.me/${number}?text=${messageText}`;
    }
  }


  return (
    <header className='header' >
      <div className='header_items' >
        <img className='icons' src={images.facebook} alt='facebook' />
        <img className='icons' src={images.instagram} alt='instagram' />

        <div onClick={() => whatsapp()} ><img className='icons' src={images.whatsapp} alt='whatsapp' />
          <p className='txt' >&nbsp;&nbsp;+91&nbsp;9087155559</p>
        </div>
        <div onClick={() => { window.location.href = `tel:${number}` }}>
          <img className='icons' src={images.telephone} alt='telephone' /><p className='txt' >&nbsp;&nbsp;+91&nbsp;9087155559</p>
        </div>
        
        <div onClick={() => email()}>
        <img className='icons' src={images.email} alt='email' /><p className='txt' >&nbsp;&nbsp;leoholidays.coimbatore@gmail.com</p>
        </div>

      </div>
    </header>
  )
}

export default Header