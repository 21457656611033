import React, { useEffect, useState,useRef } from "react";
import "./Slider.css"

const delay = 2500;

function Slider({slides}) {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex(activeIndex === slides.length - 1 ? 0 : activeIndex + 1);
      }, 2500);
  
      return () => clearInterval(interval);
    }, [activeIndex]);

    return (
        <div className="slideshow">
            <div
                className="slideshowSlider"
                style={{ transform: `translate3d(${-activeIndex * 100}%, 0, 0)` }}
            >
                {slides.map((image, index) => (
                    <img className="slide" key={index} src={image} />
                ))}
            </div>
        </div>
    );
}

export default Slider;
