import React from 'react'
import "./Card.css"

function Card({ image, text, size }) {
    return (
        <div className='card' >
            <img src={image} alt='card' style={{maxWidth:size.width,maxHeight:size.width}} />
            <div className='card_text' > 
                {text}
            </div>
        </div>
    )
}

export default Card